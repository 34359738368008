<template>
    <md-dialog class="pay-confirm" ref="payConfirm">
        <md-dialog-title>
            <span>{{ title }}</span>
            <strong class="amount">Amount: {{ amount | currency }}</strong>
        </md-dialog-title>
        <stripe-select-card :amount="amount" :email="email" @cancel="close" @confirm="confirm" v-if="!useTilled && showCard"></stripe-select-card>
        <tilled-select-card :amount="amount" :email="email" @cancel="close" @confirm="confirm" v-if="useTilled && showCard"></tilled-select-card>
    </md-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { states, country } from "@/config";
import StripeSelectCard from "@/components/Stripe/StripeSelectCard";
import TilledSelectCard from "@/components/Tilled/TilledSelectCard";

export default {
    props: {
        email: { default: null },
        amount: { default: null },
        title: { default: "Pay Order" },
        type: { default: "stripe" }
    },
    components: {
        StripeSelectCard,
        TilledSelectCard
    },
    data() {
        return {
            isInit: false,
            showCard: false
        };
    },
    computed: {
        useTilled() {
            return this.type === "EzTimePay";
        }
    },
    methods: {
        open() {
            this.$refs["payConfirm"].open();
            this.showCard = true;
        },
        close() {
            this.$refs["payConfirm"].close();
            this.showCard = false;
        },
        confirm({ card }) {
            this.close();
            this.$emit("confirm", { card });
        }
    },
    async mounted() {}
};
</script>
<style lang="less">
.pay-confirm {
    .md-dialog-title {
        margin-bottom: 0;
        padding: 20px 16px 10px;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        .amount {
            font-size: 15px;
            margin-right: 20px;
        }
    }

    .md-dialog {
        .md-card {
            max-height: 70vh;
        }
    }

    .md-dialog-content {
        padding: 0;
    }
}
</style>
