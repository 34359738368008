<template>
    <div class="tilled-select-card">
        <md-card v-if="!loading && !showAddNewCard && cards.length">
            <md-card-header>
                Please Select EzTimePay Cards
                <router-link to="/admin/EzTimePay/cards" v-if="!email">(Manage my cards)</router-link>
            </md-card-header>
            <md-card-content>
                <div class="cards">
                    <div class="card" :key="ix" :md-value="card.id" v-for="(card, ix) in cards">
                        <md-radio :md-value="card.id" v-model="selectedCard">
                            <span class="brand">{{ card.card.brand }}</span>
                            <span class="no">************{{ card.card.last4 }}</span>
                            <span class="name">{{ card.nick_name }}</span>
                        </md-radio>
                        <div class="btns">
                            <md-button class="md-icon-button" @click="startEditCard(card)">
                                <md-icon>edit</md-icon>
                            </md-button>
                            <md-button class="md-icon-button md-accent" @click="startDelete(card)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
                <a @click.prevent="startAddNewCard" style="cursor:pointer">Use a new card</a>
            </md-card-content>
            <md-card-actions>
                <md-button @click="cancel">Cancel</md-button>
                <md-button @click="confirm" class="md-primary">Confirm</md-button>
            </md-card-actions>
        </md-card>

        <tilled-new-card :id="selectedCard ? selectedCard.id : null" :email="email" @cancel="cancel" @confirm="newCardConfirm" v-if="showAddNewCard || (!cards.length && !loading)"></tilled-new-card>
        <confirm ref="deleteConfirm"></confirm>
    </div>
</template>
<script>
import TilledNewCard from "@/components/Tilled/TilledNewCard";
import Confirm from "@/components/modal/Confirm";
import { mapGetters } from "vuex";

export default {
    props: {
        email: { default: "" }
    },
    components: {
        TilledNewCard,
        Confirm
    },
    data() {
        return {
            loading: true,
            showAddNewCard: false,
            cards: [],
            selectedCard: null
        };
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        })
    },
    methods: {
        async loadCards() {
            this.loading = true;
            this.showAddNewCard = false;
            this.selectedCard = null;
            let result = await this.$store.dispatch("crud/get", {
                api: `EzTimePay/cards`,
                params: {
                    email: this.email
                }
            });
            this.cards = result;
            this.loading = false;
            if (this.cards.length) {
                let defaultCard = this.cards.find(c => c.default);
                let selectedCard = defaultCard || this.cards[0];
                this.selectedCard = selectedCard && selectedCard.id;
            }
        },
        cancel() {
            if (this.showAddNewCard) {
                this.showAddNewCard = false;
            } else {
                this.$emit("cancel");
            }
        },
        newCardConfirm() {
            this.loadCards();
        },
        confirm() {
            this.$emit("confirm", { card: this.selectedCard });
        },
        startAddNewCard() {
            this.showAddNewCard = true;
            this.selectedCard = null;
        },
        startEditCard(card) {
            this.showAddNewCard = true;
            this.selectedCard = card;
        },
        async startDelete(card) {
            if (!this.hasRole("staff")) {
                if (this.cards.length <= 1) {
                    const message = "There must be at least (1) valid card on file";
                    this.$store.commit("setMessage", message);
                    throw message;
                }
            }

            let result = await this.$refs.deleteConfirm.process(`Would you like to delete this card?`);
            if (result.action == "confirm") {
                let result = await this.$store.dispatch("crud/delete", {
                    api: `EzTimePay/cards/${card.id}`
                });
                this.$store.commit("setMessage", "Delete successfully");
                this.loadCards();
            }
            console.info(result);
        }
    },
    mounted() {
        this.loadCards();
    }
};
</script>
<style lang="less">
.tilled-select-card {
    .md-card {
        margin: 0;
    }
    .md-card-header {
        padding: 0 16px;
    }

    .cards {
        padding-top: 10px;
    }
    .card {
        display: flex;
        justify-content: space-between;
        .md-radio {
            display: flex;
            margin: 10px 8px 8px 0;
            .md-radio-label {
                padding-left: 15px;
                line-height: 24px;
                height: auto;
                flex: 1;
            }
            .brand {
                display: inline-block;
                margin-right: 15px;
                // width: 50px;
            }
            .no {
                display: inline-block;
                margin-right: 15px;
                // width: 140px;
            }
            .name {
                display: inline-block;
                margin-right: 15px;
            }
        }
    }
}
</style>
