<template>
    <div class="stripe-select-card">
        <md-card v-if="!loading && !showAddNewCard && cards.length">
            <md-card-header>
                Please Select Stripe Cards
                <router-link to="/admin/stripe/cards" v-if="!email">(Manage my cards)</router-link>
            </md-card-header>
            <md-card-content>
                <md-radio :key="ix" :md-value="card.id" class="card" v-for="(card,ix) in cards" v-model="selectedCard">
                    <span class="brand">{{card.brand}}</span>
                    <span class="no">************{{card.last4}}</span>
                    <span class="name">{{card.name}}</span>
                </md-radio>
                <a @click.prevent="showAddNewCard=true" style="cursor:pointer">Use a new card</a>
            </md-card-content>
            <md-card-actions>
                <md-button @click="cancel">Cancel</md-button>
                <md-button @click="confirm" class="md-primary">Confirm</md-button>
            </md-card-actions>
        </md-card>

        <stripe-new-card :email="email" @cancel="cancel" @confirm="newCardConfirm" v-if="showAddNewCard || (!cards.length&&!loading)"></stripe-new-card>
    </div>
</template>
<script>
import StripeNewCard from "@/components/Stripe/StripeNewCard";

export default {
    props: {
        email: { default: "" }
    },
    components: {
        StripeNewCard
    },
    data() {
        return {
            loading: true,
            showAddNewCard: false,
            cards: [],
            selectedCard: null
        };
    },
    methods: {
        async loadCards() {
            this.loading = true;
            this.showAddNewCard = false;
            let result = await this.$store.dispatch("crud/get", {
                api: `stripe/cards`,
                params: {
                    email: this.email
                }
            });
            this.cards = result;
            this.loading = false;
            if (this.cards.length) {
                let defaultCard = this.cards.find(c => c.default);
                let selectedCard = defaultCard || this.cards[0];
                this.selectedCard = selectedCard && selectedCard.id;
            }
        },
        cancel() {
            if (this.showAddNewCard) {
                this.showAddNewCard = false;
            } else {
                this.$emit("cancel");
            }
        },
        newCardConfirm({ token, customer }) {
            console.info(token, customer);
            this.loadCards();
        },
        confirm({ token, customer }) {
            this.$emit("confirm", { card: this.selectedCard, token, customer });
        }
    },
    mounted() {
        this.loadCards();
    }
};
</script>
<style lang="less">
.stripe-select-card {
    .md-card {
        margin: 0;
    }
    .md-card-header {
        padding: 0 16px;
    }

    .md-radio.card {
        display: flex;
        .md-radio-label {
            padding-left: 15px;
            line-height: 24px;
            height: auto;
            flex: 1;
        }
        .brand {
            display: inline-block;
            margin-right: 15px;
            // width: 50px;
        }
        .no {
            display: inline-block;
            margin-right: 15px;
            // width: 140px;
        }
        .name {
            display: inline-block;
            margin-right: 15px;
        }
    }
}
</style>

